export const environment = {
  production: false,
  environment: 'development',
  appVersion: require('../../package.json').version,
  consentDomain: 'snookervlaanderen-dev.resonate.be',
  settings: {
    documentBase: 'https://snookervlaanderen-api-dev.resonate.be',
    serviceBase: 'https://snookervlaanderen-api-dev.resonate.be/api/',
    graphQLBase: 'https://snookervlaanderen-api-dev.resonate.be/graphql/'
  },
  auth0: {
    domain: 'snookervlaanderen-dev-resonate.eu.auth0.com',
    audience: 'https://snookervlaanderen-api-dev.resonate.be',
    clientId: 'Iy9fKymlTts7HHXuxE8M5y7LnUZnjelv',
    redirectUri: 'https://snookervlaanderen-dev.resonate.be/callback',
  }
};
